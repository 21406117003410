const getError = (err) => {
  let error = err && err.response ? err.response : null;
  if (error) {
    console.log(error);
  }
  if (error) {
    if (error.data && error.data.error) {
      if (typeof error.data.error === "string") {
        return error.data.error;
      }
      let firstError = error.data.error[Object.keys(error.data.error)[0]];
      return firstError.msg;
    } else if (error.data?.message) {
      return error.data?.message;
    }
  }
};
export default getError;
